<template>
  <section class="store-setup">
    <p class="md-display-2">{{ $translate("store_setup.title") }}</p>
    <p class="md-body-2">{{ $translate("store_setup.message") }}</p>

    <md-steppers :md-active-step.sync="active_step">
      <md-step
        id="payment_gateway"
        :md-label="$translate('store_setup.labels.payment_gateway')"
        :md-description="$translate('store_setup.labels.payment_gateway')"
        :md-done.sync="payment_gateway"
      >
        <payment_gateway_configuration
          :can_user_manage_configuration="permissions.configuration.sections.payment_gateway.manage"
          :store_setup_import="true"
          @is_filled_out="value => update_step_state('payment_gateway', value)"
        />
        <md-button
          class="md-raised md-primary store-setup__navigation-button"
          @click="save_and_next_step('store_address')"
        >{{ $translate("store_setup.save_button") }}</md-button>
      </md-step>

      <md-step
        id="store_address"
        :md-label="$translate('store_setup.labels.store_address')"
        :md-description="$translate('store_setup.labels.store_address')"
        :md-done.sync="store_address"
      >
        <p class="md-headline">
          {{ $translate("store_setup.labels.store_address") }}
        </p>
        <address_config
          :can_user_manage_configuration="permissions.configuration.sections.shipping.manage"
          :store_setup_import="true"
        />
        <md-button
          class="md-raised md-primary store-setup__navigation-button"
          @click="save_and_next_step(undefined, true)"
        >{{ $translate("store_setup.save_button") }}</md-button>
        <md-button
          class="store-setup__navigation-button"
          @click="active_step = 'payment_gateway'"
          >{{ $translate("back") }}</md-button
        >
      </md-step>
    </md-steppers>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  ADMIN,
  CONFIGURATION_STORE,
  USER_STORE
} from "../../constants/others_constants";
import payment_gateway_configuration from "../utils/configuration/payment_gateway_configuration";
import address_config from "../utils/address_config";
import { update_project_domains } from "../../constants/endpoints/firebase";
import { user_domain_email_notification } from "../../constants/endpoints/other";
import { SAVE_CONFIGURATION } from "../../stores/Admin/configuration/constants";
import { ADD_GLOBAL_ERROR, UPDATE_LOADER } from "../../stores/Admin/constants";
import { USER_STORE_TOUR_TOGGLE } from "../../stores/Admin/user/constants";

export default {
  components: {
    payment_gateway_configuration,
    address_config
  },
  data() {
    return {
      active_step: "payment_gateway",
      payment_gateway: false,
      store_address: false,
      step_states: {
        payment_gateway: false,
        store_address: false
      }
    };
  },
  computed: {
    ...mapState(USER_STORE, ["permissions", "user_info"]),
    ...mapState(CONFIGURATION_STORE, [
      "error_list",
      "shipping_options",
      "project_config",
      "paypal_config",
      "project_name"
    ])
  },
  mounted() {
    this.user_store_tour_toggle({ value: true, type: "marketplace" })
  },
  methods: {
    ...mapMutations(ADMIN, {
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER
    }),
    ...mapMutations(USER_STORE, {
      user_store_tour_toggle: USER_STORE_TOUR_TOGGLE,
    }),
    ...mapActions(CONFIGURATION_STORE, {
      save_configuration: SAVE_CONFIGURATION
    }),
    update_step_state(name, state) {
      this.step_states[name] = state
    },
    async save_and_next_step(next, is_last_step) {
      try {
        if (this.project_config.domain_updated && this.project_config.domain) {
          this.update_loader()
          await update_project_domains({ domain: this.project_config.domain })
          await user_domain_email_notification({
            domain: this.project_config.domain,
            user_id: this.user_info.id,
            email: this.user_info.email,
            name: this.user_info.name,
            project_name: this.project_name
          })
          delete this.project_config.domain_updated
        }

        const step_values = {
          payment_gateway: {
            paypal_config: this.paypal_config
          },
          store_address: {
            shipping_options: this.shipping_options
          }
        }
        await this.save_configuration(step_values[this.active_step])

        if (is_last_step) this.$router.push("/admin/products/imported")
        else this.active_step = next
      } catch ({ message }) {
        this.add_global_error({ message })
        this.update_loader(false)
      }
    }
  }
};
</script>

<style lang="scss">
@use "../../../../styles/_admin-constants" as *;

.store-setup {
  .md-stepper {
    padding: 0 $half-default-size;
  }

  .address-config__inputs {
    margin-top: $default-size;
  }

  &__navigation-button {
    float: right;
  }

  .md-display-2 {
    margin-top: 0;
    padding-top: $default-size;
  }
}
</style>
